import * as React from "react"
import ContactusSection from "../components/mainpage/ContactusSection"
import FooterSection from "../components/mainpage/FooterSection"
import Menuzord from "../components/mainpage/Menuzord"
import { graphql } from "gatsby"
import ViewportBlock from "../components/viewportBlock"
import Seo from "../components/seo"
// styles
import * as styles from "../css/licenses.module.css"

const LicensesPage = ({ data }) => {
  const nodes = data.directus.licenses
  return (
    <>
      <Seo title="Лицензии"/>
      <Menuzord pageName="licenses" light={true} />
      <section className={styles.singleBlog}>
        <ViewportBlock className="container reveal">
          <div className={styles.sectionHead}>
            <h1 className={styles.sectionTitle}>Лицензии</h1>  
          </div>
          <div className={styles.row}>
            <ol className={styles.licensesList}>
            {nodes.map(license => {
              return (
                <li key={license.id}><a href={license.file.imageFile.publicURL}>{license.title}</a></li>
              )
            })}
            </ol>
          </div>
        </ViewportBlock>
      </section>
      <ContactusSection />
      <FooterSection />
    </>
  )
}

export default LicensesPage

export const query = graphql`
  query LicensesList {
    directus {
      licenses {
        id
        title
        file {
          imageFile {
            publicURL
          }
          id
        }
      }
    }
  }
`